import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import FilterProperties from "../components/properties/FilterProperties"
import FeaturedProperties from "../components/properties/FeaturedProperties"

import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { FaCheck } from "react-icons/fa"

const EntreguenosSuPropiedad = () => {
  const [pathname, setPathname] = useState("")
  useEffect(() => {
    setPathname(window.location.href)
  }, [])
  return (
    <Layout>
      <SEO
        title={`Venda, arriende y administre con nosotros`}
        pathname={pathname}
      />
      <BannerTop title="Venda, arriende y administre con nosotros" />
      <div class="container contents single">
        <div class="row">
          <div class="span9 main-wrap">
            <div class="main">
              <div class="inner-wrapper">
                <article
                  class="page type-page clearfix"
                  style={{ padding: 20 }}
                >
                  <StaticQuery
                    query={graphql`
                      query {
                        file(relativePath: { eq: "fondo1.jpg" }) {
                          childImageSharp {
                            fluid(quality: 90, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    `}
                    render={data => {
                      // Set ImageData.
                      const imageData = data.file.childImageSharp.fluid
                      return (
                        <BackgroundImage
                          Tag="section"
                          className="page-head"
                          fluid={imageData}
                          style={{ height: "300px" }}
                          backgroundColor={`#040e18`}
                        ></BackgroundImage>
                      )
                    }}
                  />
                  <hr />
                  <h3 style={{ fontSize: 27 }}>
                    Venda y Arriende con nosotros!
                  </h3>
                  <br />
                  <h5>
                    Nuestros 17 años de experiencia, nuestro gran equipo
                    profesional, nuestras plataformas y servicios con tecnología
                    inteligente, nos convierten en su mejor opción.
                  </h5>

                  <h3 style={{ margin: "20px 0px" }}>Venda con nosotros</h3>
                  <p>Deje la venta de su propiedad en manos expertas:</p>
                  <div class="seller-button">
                    <div class="ev-btn-caption-container">
                      <div class="ev-btn-caption" style={{ marginLeft: 10 }}>
                        <p className="item-list-check">
                          <FaCheck /> Brindamos asesoramiento profesional e
                          individual
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Realizamos una estimación competente del
                          precio de venta
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Garantizamos la dedicación exclusiva de un
                          Agente Inmobiliario
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Preparamos la ficha técnica, legal y
                          fotografías de su propiedad
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Invertimos en un plan de marketing
                          individual para su propiedad, publicando
                          profesionalmente en los portales y plataformas
                          inmobiliarias más destacadas del país
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Planeamos de manera óptima las visitas a
                          través de órdenes electrónicas supervisadas
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Informamos regularme sobre el estado de
                          venta de su propiedad
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Negociación contractual profesional y
                          apoyo legal permanente
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Ofrecemos servicios post venta integral
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <h3 style={{ margin: "10px 0px" }}>
                    Arriende y administre con nosotros
                  </h3>
                  <p>
                    No se preocupe por el arriendo y la administración de su
                    propiedad, conozca nuestras ventajas:
                  </p>
                  <div class="seller-button">
                    <div class="ev-btn-caption-container">
                      <div class="ev-btn-caption" style={{ marginLeft: 10 }}>
                        <p className="item-list-check">
                          <FaCheck /> Garantizamos la dedicación exclusiva de un
                          Agente Inmobiliario
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Invertimos en un plan de marketing
                          individual para su propiedad, publicando
                          profesionalmente en los portales y plataformas
                          inmobiliarias más destacadas del país
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Recomendamos el mejor precio de arriendo.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Realizamos la búsqueda, evaluación,
                          calificación y selección del mejor candidato
                          arrendatario.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Contamos con un promedio de arriendo no
                          superior a 20 días y analizamos y calificamos en menos
                          de 24 horas.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Proporcionamos una póliza de seguro de
                          garantía sin costo para usted, que lo protegerá de
                          cuentas impagas de electricidad, gas, agua, gasto
                          común y daños no reparados o no cubiertos por el
                          arrendatario.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Nuestra tasa de rotación es menor al 0,1%,
                          tasa de morosidad inferior al 0,5% y una tasa de
                          vacancia no mayor a dos semanas.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Administramos profesionalmente su
                          propiedad, haciéndonos cargo del cobro del arriendo,
                          servicios básicos, multas y gasto común, certificamos
                          el inventario de la propiedad, mantenemos imágenes
                          digitales y videos para resguardo y seguridad de las
                          partes.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Inspeccionamos la propiedad las veces que
                          sean necesarias, realizamos los servicios de
                          mantención y reparación,
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Ocupamos las mejores herramientas
                          tecnológicas, legales y financieras.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Somos una empresa certificada para operar
                          con seguros de arriendos y supervisados por la CMF.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> No cobramos comisión de servicio en los
                          períodos de vacancia.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Nuestra plataforma de pago de
                          administración cuenta con certificado de seguridad
                          SSL.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Contamos con sitio web electrónico
                          personalizado con acceso seguro a propietarios y
                          arrendatarios, donde podrán acceder en tiempo real a
                          toda la información de las propiedades en arriendo.
                        </p>
                        <p className="item-list-check">
                          <FaCheck /> Contamos además con múltiples formas de
                          pago: pagos con tarjeta, red-compra, depósitos en
                          cuenta corriente, transferencia electrónica, Servipag,
                          entre otros.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </article>
              </div>
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
              <FeaturedProperties quantity={2} />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EntreguenosSuPropiedad
